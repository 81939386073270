import React from "react"
import Transportation from "../../components/Content/Transportation"
import Seo from "../../components/seo"

export default props => (
  <React.Fragment>
    <Seo
      title={`Transportes`}
      description={`Ofrecemos un servicio de transporte único y profesional
`}
    />

    <Transportation location={props.location} />
  </React.Fragment>
)
